import { useState, FC } from 'react'
import {
    useFloating,
    autoUpdate,
    offset,
    useClick,
    useDismiss,
    useInteractions,
    FloatingFocusManager,
} from '@floating-ui/react'

import { BlockPicker } from 'react-color'
import { Label } from '../Label'

type Props = {
    className?: string
    disabled?: boolean
    label?: string
    color: string
    onChange: (color: string) => void
}

export const ColorPicker: FC<Props> = ({
    color,
    onChange,
    className,
    label,
    disabled,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    // Floating UI positioning
    const { refs, floatingStyles, context } = useFloating({
        placement: 'bottom',
        whileElementsMounted: autoUpdate,
        open: isOpen,
        onOpenChange: disabled ? undefined : setIsOpen,
        middleware: [offset(8)],
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)

    const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss])

    return (
        <div className={className}>
            {label ? <Label>{label}</Label> : null}
            <div
                ref={refs.setReference}
                style={{
                    height: 34,
                    width: 34,
                    backgroundColor: color,
                    borderRadius: 8,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                }}
                {...getReferenceProps()}
            />

            {isOpen ? (
                <FloatingFocusManager context={context} modal={true}>
                    <div
                        ref={refs.setFloating}
                        style={{ ...floatingStyles, zIndex: 999 }}
                        {...getFloatingProps()}
                    >
                        <BlockPicker
                            color={color}
                            onChange={(color) => onChange(color.hex)}
                        />
                    </div>
                </FloatingFocusManager>
            ) : null}
        </div>
    )
}
