import { FC, ReactNode } from 'react'
import cx from 'classnames'

import styles from './style.module.scss'

type Props = {
    children: ReactNode
    className?: string
    useStrongLabel?: boolean
    required?: boolean
}

export const Label: FC<Props> = ({ children, className, required, useStrongLabel }) => {
    return (
        <label className={cx(styles.label, className, { 'semi-strong': useStrongLabel })}>
            {children}
            {required ? '*' : ''}
        </label>
    )
}
