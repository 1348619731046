import { useEffect, useState } from 'react'
import cx from 'classnames'
import * as icons from 'react-bootstrap-icons'

import { Button, Card, Icon } from '.'

type Props = {
    children: React.ReactNode
    title: string
    titleIcon: keyof typeof icons
    initiallyExpanded?: boolean
    disabled?: boolean
}

export const CollapsableCard = ({
    children,
    title,
    titleIcon,
    initiallyExpanded,
    disabled,
}: Props) => {
    const [isExpanded, setIsExpanded] = useState(initiallyExpanded)

    useEffect(() => setIsExpanded(initiallyExpanded), [initiallyExpanded])

    return (
        <Card variant="border" className={cx('py-2', { 'rounded-md': !isExpanded })}>
            <div
                className={cx('d-flex align-items-center gap-2 pe-2', {
                    'pb-2': isExpanded,
                })}
                style={{ cursor: disabled ? 'default' : 'pointer' }}
                onClick={() => {
                    if (disabled) return
                    setIsExpanded(!isExpanded)
                }}
            >
                <Icon icon={titleIcon} className="fs-24" />
                <h5 className="me-auto">{title}</h5>
                <Button
                    disabled={disabled}
                    size="sm"
                    variant="secondaryText"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <Icon
                        className="fs-16 strong"
                        icon={isExpanded ? 'ChevronUp' : 'ChevronDown'}
                    />
                </Button>
            </div>
            {isExpanded ? (
                <div className="border-top-neutral-200 pt-4 pb-4">{children}</div>
            ) : null}
        </Card>
    )
}
