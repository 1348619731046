import { FC, ReactNode } from 'react'

import { Label } from 'ds/components'
import { InputInfo } from '../InputInfo'

import { FormContext } from 'lib/hooks/useForm'
import styles from './style.module.scss'

type Props = {
    className?: string
    rows?: number
    error?: string
    required?: boolean
    disabled?: boolean
    label: ReactNode
    value: string
    formContext?: FormContext
    placeholder?: string

    onBlur?: () => void
    onChange: (value: any) => void
}

export const Textarea: FC<Props> = ({
    className,
    label,
    required,
    error,
    value,
    disabled,
    rows,
    onBlur,
    onChange,
    formContext,
    placeholder,
}) => {
    return (
        <div className={className}>
            <Label required={required || formContext?.required}>{label}</Label>
            <textarea
                disabled={disabled}
                className={styles.textarea}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                placeholder={placeholder}
                rows={rows}
                value={value}
            />
            <InputInfo error={error || formContext?.displayError} />
        </div>
    )
}
