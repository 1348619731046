import { FC, ReactNode } from 'react'
import { es } from 'date-fns/locale'
import cx from 'classnames'

import { Label } from '../Label'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import inputStyles from '../Input/style.module.scss'

import { castDate } from 'lib/utils/castDate'
import { InputInfo } from '../InputInfo'
import { FormContext } from 'lib/hooks/useForm'
import { dateToDateString } from 'lib/utils/dateStringUtils'

registerLocale('es', es)

type Props = {
    className?: string
    useStrongLabel?: boolean
    label?: string
    placeholder?: string
    value: Date | null | undefined
    required?: boolean
    error?: string
    help?: ReactNode
    formContext?: FormContext
    disabled?: boolean
    minDate?: Date
    maxDate?: Date

    onBlur?: () => void
    onChange?: (value: Date | null) => void
    onDateStringChange?: (value: string | null) => void
}

export const DatePicker: FC<Props> = ({
    className,
    useStrongLabel,
    label,
    placeholder,
    value,
    onChange,
    required,
    onBlur,
    error,
    help,
    formContext,
    onDateStringChange,
    disabled,
    minDate,
    maxDate,
}) => {
    return (
        <div className={className}>
            {label ? (
                <Label
                    useStrongLabel={useStrongLabel}
                    required={required || formContext?.required}
                >
                    {label}
                </Label>
            ) : null}
            <ReactDatePicker
                disabled={disabled}
                placeholderText={placeholder}
                locale="es"
                className={cx(inputStyles.input, 'ease')}
                dateFormat="P"
                minDate={minDate}
                maxDate={maxDate}
                selected={castDate(value)}
                onChange={(date) => {
                    if (onChange) onChange(date)
                    else if (onDateStringChange) {
                        onDateStringChange(date ? dateToDateString(date) : null)
                    }
                }}
                onBlur={onBlur}
            />
            <InputInfo error={error || formContext?.displayError} help={help} />
        </div>
    )
}
