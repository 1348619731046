import { FC, ReactNode, useMemo } from 'react'
import cx from 'classnames'

import { Label } from '../Label'
import { InputInfo } from '../InputInfo'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import inputStyles from '../Input/style.module.scss'
import { castDecimal } from 'lib/utils/castDecimal'
import { FormContext } from 'lib/hooks/useForm'

type Props = {
    className?: string
    useStrongLabel?: boolean
    label?: ReactNode
    required?: boolean
    error?: string
    help?: ReactNode
    placeholder?: string
    decimalScale?: number
    disabled?: boolean
    allowNegative?: boolean
    formContext?: FormContext
    max?: number
    min?: number

    variant?: 'currency' | 'percent'
    value: string | number | undefined | null
    onChange: (val: number | null) => void
    onBlur?: () => void
}

export const NumberInput: FC<Props> = ({
    className,
    useStrongLabel,
    label,
    required,
    error,
    help,
    placeholder,
    value,
    decimalScale,
    onChange,
    variant,
    onBlur,
    disabled,
    allowNegative,
    formContext,
    max,
    min,
}) => {
    const isCurrency = variant === 'currency'
    const isPercent = variant === 'percent'

    const val = useMemo(() => {
        if (isPercent) return castDecimal(value).times(100).toNumber()

        // If decimalScale is greater than 3, return the value as a number
        // so that there aren't so many trailing zeros
        if (decimalScale && value && decimalScale > 3) return Number(value)

        return value
    }, [isPercent, value, decimalScale])

    const scale = decimalScale ?? (isPercent ? 4 : 2)

    const isAllowed = useMemo(() => {
        const hasMax = !!max || max === 0
        const hasMin = !!min || min === 0
        if (!hasMax && !hasMin) return undefined

        return ({ floatValue }: NumberFormatValues) => {
            if (floatValue === undefined) return true
            if (hasMin && floatValue < min) return false
            if (hasMax && floatValue > max) return false
            return true
        }
    }, [max, min])

    return (
        <div className={className}>
            {label ? (
                <Label
                    required={required || formContext?.required}
                    useStrongLabel={useStrongLabel}
                >
                    {label}
                </Label>
            ) : null}
            <NumericFormat
                allowNegative={allowNegative}
                isAllowed={isAllowed}
                prefix={isCurrency ? '$' : undefined}
                suffix={isPercent ? '%' : undefined}
                thousandSeparator={isCurrency ? ',' : undefined}
                decimalScale={scale}
                value={val}
                placeholder={placeholder}
                disabled={disabled}
                className={cx(inputStyles.input, 'ease')}
                onValueChange={({ floatValue }) => {
                    const newVal = isPercent
                        ? castDecimal(floatValue).dividedBy(100).toNumber()
                        : floatValue ?? null

                    onChange(newVal)
                }}
                onBlur={onBlur}
            />
            <InputInfo error={error || formContext?.displayError} help={help} />
        </div>
    )
}
