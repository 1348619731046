import exportFromJSON from 'export-from-json'
import { formatDateTimestamp } from './formatDateTimestamp'
import { flatten } from 'lodash'

const exportType = exportFromJSON.types.csv

export const downloadToCsv = ({ data, getSubRows, filename, columns }) => {
    const now = new Date()
    const fileName = `${filename}-${formatDateTimestamp(now)}`

    const dataToExport = data.map((row) => {
        const rowToExport = row.exportToExcel || row
        if (getSubRows) {
            const rowsToExport = []
            flattenSubRows(rowsToExport, rowToExport, getSubRows)
            return rowsToExport
        }
        return [rowToExport]
    })

    const usesExportToExcel = data[0].exportToExcel
    let fields = undefined
    if (columns.length > 0 && !usesExportToExcel) {
        fields = {}
        columns.forEach(
            (column) => (fields[column.accessorKey] = column.header || column.accessorKey)
        )
    }

    exportFromJSON({
        data: flatten(dataToExport),
        fileName,
        exportType,
        fields,
    })
}

const flattenSubRows = (subRows, currRow, getSubRow) => {
    const currSubRows = getSubRow(currRow)
    delete currRow.subRows
    delete currRow.children
    subRows.push(currRow)

    if (!currSubRows) return

    currSubRows.forEach((subRow) => flattenSubRows(subRows, subRow, getSubRow))
}
